import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import htmlSerializer from '../../utils/htmlSerializer'
import { RichText } from 'prismic-reactjs'

const StyledCard = styled.div`
  position: relative;
  padding: 30px 15px;
  background-color: white;
  border-radius: 22px;
  &.small {
    :first-of-type {
      margin-bottom: 17px;
    }
  }
  &.large {
    margin-bottom: 17px;
    height: 100%;
  }
`
const TopLayer = styled.div`
  border-radius: 17px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: row;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    &.large {
      display: block;
    }
  }
`
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  h3 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 3px;
    color: #4f4f4f;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    h3 {
      font-size: 21px;
    }
  }
`
const Author = styled.p`
  color: #424141;
  font-size: 14px;
  margin-bottom: 5px;
`

const ImageWrap = styled.div`
  .gatsby-image-wrapper {
    width: 150px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    .gatsby-image-wrapper {
      width: 110px;
      margin-right: 20px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0 auto;
    &.large {
      width: 70%;
      max-height: 200px;
      .gatsby-image-wrapper {
        width: auto;
        margin-right: 0;
      }
    }
  }
`

const Info = styled.div`
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-top: 0;
    &.large {
      padding: 30px 15px 0px;
    }
  }
`

const Excerpt = styled.div`
  color: #424141;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Category = styled.span`
  display: inline-block;
  font-size: 13px;
  border: solid 1px;
  padding: 3px 6px;
  margin-bottom: 15px;
  border-radius: 5px;
  line-height: 1;
  margin-right: 7px;
  &.orange {
    color: ${({ theme }) => theme.colors.whyOrange};
  }
  &.green {
    color: ${({ theme }) => theme.colors.whyGreen};
  }
  &.blue {
    color: ${({ theme }) => theme.colors.whyBlue};
  }
  &.red {
    color: ${({ theme }) => theme.colors.whyMagenta};
  }
  &.yellow {
    color: ${({ theme }) => theme.colors.whyYellow};
  }
`

const CardDetail = ({
  title,
  image,
  link,
  author,
  excerpt,
  size,
  subcategories,
  category
}) => {
  return (
    <StyledCard className={size}>
      <Link to={`/stories/${link}`}>
        <TopLayer className={size}>
          <ImageWrap className={size}>
            <Img fluid={image?.fluid} alt={image?.alt} />
          </ImageWrap>
          <Info className={size}>
            {subcategories.document === null ? (
              subcategories.map((cat, index) => {
                return (
                  <Category
                    key={`${title}-${index}`}
                    className={
                      cat.subcategory?.document?.data.parent_category.document
                        .data.color
                    }
                  >
                    {cat.subcategory?.document?.data.name}
                  </Category>
                )
              })
            ) : category ? (
              <Category className={category.color}>{category.name}</Category>
            ) : null}
            <Title>
              <h3>{title}</h3>
            </Title>
            <Author>{author}</Author>
            <Excerpt>
              <RichText render={excerpt} htmlSerializer={htmlSerializer} />
            </Excerpt>
          </Info>
        </TopLayer>
      </Link>
    </StyledCard>
  )
}

export default CardDetail
