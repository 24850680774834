import React from 'react'
import Card from '../elements/CardWithIcon'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: stretch;
  flex-direction: column;
  background: transparent;
  padding: 0 25px 80px;
  max-width: 1075px;
  gap: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 10px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    justify-content: space-between;
  }
`

const CardSection = ({ cardContent }) => {
  return (
    <Flex>
      {cardContent.map(
        (card, index) =>
          index < 2 && (
            <Card
              key={index}
              color="blue"
              title={card.title.text}
              link={card.article_link.url}
              image={card.image}
              subheading={card.subtitle.text}
            />
          )
      )}
    </Flex>
  )
}

export default CardSection
