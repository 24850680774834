import React, { useEffect } from 'react'
import styled from 'styled-components'
import LunrSearchBar from '../lunr-search'

const AnswerBar = ({ initialQuery }) => {
  return (
    <Wrapper>
      <LunrSearchBar initialQuery={initialQuery} />
    </Wrapper>
  )
}

export default AnswerBar

const Wrapper = styled.div`
  padding-top: 10px;
  z-index: 10;
  position: relative;

  input {
    width: calc(100% - 56px);
    height: 58px;
    border-radius: 12px;
    border: none;
    padding: 30px 8px;
    color: #575759;
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.body};
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 78px;
      width: calc(100% - 95px);
      font-size: 19px;
      padding: 30px 14px;
    }
    :focus-visible {
      outline: none;
    }
  }
  button {
    width: 50px;
    height: 60px;
    border: none;
    border-radius: 9px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: ${({ theme }) => theme.colors.whyOrange};
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 78px;
      width: 90px;
    }
  }
  svg {
    margin-top: 6px;
    color: #fff;
    height: 30px;
    width: 30px;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 36px;
      width: 36px;
    }
  }
 
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    svg {
      margin-top: 5px;
      height: 55px;
      width: 55px;
    }
  }
`
