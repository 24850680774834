import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Card from '../components/elements/CardWithIcon'
import SEO from '../components/primary/SEO'
import Container from '../components/primary/Container'
import HomeSlider from '../components/page-sections/HomeSlider'
import AnswerBar from '../components/page-sections/AnswerBar'
import styled from 'styled-components'
import Layout from '../components/primary/Layout'
import Map from '../images/banner.png'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../utils/htmlSerializer'
import Quotes from '../images/quotes.svg'
import Exclaim from '../images/exclamation.svg'
import Question from '../images/question-marks.inline.svg'
import ArticlePreview from '../components/page-sections/ArticlePreview'
import CardSection from '../components/page-sections/CardSection'
import DontMissCard from '../components/elements/DontMissCard'

const Background = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Map});
  background-position: 50% 30%;
  height: 290px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 350px;
  }
`
const TextWrap = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  height: 80%;
`
const SliderContainer = styled.div`
  width: 100%;
  z-index: 100;
  padding-top: 40px;
  background-color: #fff;
`

const Heading = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
  h1 {
    font-size: 28px;
    line-height: 1.25em;
    font-weight: 800;
    letter-spacing: -0.5px;
  }
  h2 {
    display: block;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.7px;
  }
  h3 {
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 700;
  }
  a {
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.whyYellow};
    border-bottom: 1px solid ${({ theme }) => theme.colors.whyYellow};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
    margin-bottom: 0;
    h1 {
      font-size: 33px;
    }
    h2 {
      font-size: 18px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    h1 {
      line-height: 1.3em;
      font-size: 39px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: center;
    h1 {
      margin-bottom: 25px;
      line-height: 1.1em;
      font-size: 45px;
    }
    h2 {
      font-size: 25px;
      margin-bottom: 25px;
      max-width: 1000px;
    }
    h3,
    a {
      font-size: 25px;
    }
  }
`
const Blurb = styled.div`
  padding: 10px 0;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 840px;
  position: relative;
  p {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    font-size: 19px;
    line-height: 1.45em;
    letter-spacing: 0.5px;
    color: #4f4f4f;
  }
  h2 {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 10px;
    letter-spacing: -0.8px;
  }
  ::before {
    position: absolute;
    content: '';
    background-repeat: no-repeat;
  }
  &.exclamation {
    ::before {
      width: 110px;
      height: 280px;
      right: -20px;
      top: 20px;
      background-image: url(${Exclaim});
      @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
        width: 140px;
        right: -40px;
        top: 30px;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        width: 170px;
        height: 290px;
        right: -110px;
        top: 120px;
      }
    }
  }
  &.blue {
    padding: 0;
    h3 {
      line-height: 53px;
      margin: 60px auto 12px;
      padding: 0;
    }
    p {
      line-height: 22px;
      margin-bottom: 15px;
    }
    h2 {
      line-height: 53px;
      margin: 60px auto 25px;
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    strong {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    p {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.answer {
    width: 90%;
    text-align: center;
    p,
    h2 {
      color: #fff;
    }
    p {
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.5px;
      margin: 30px 0 50px;
    }
    span {
      color: ${({ theme }) => theme.colors.whyOrange};
      font-size: 25px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
      p {
        font-size: 24px;
      }
      span {
        font-size: 29px;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 60%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      p {
        font-size: 29px;
      }
      span {
        font-size: 32px;
      }
    }
    @media (min-width: 1300px) {
      width: 80%;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 80%;
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 22px;
      line-height: 1.6em;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    h2 {
      font-size: 53px;
    }
  }
  &.quotes {
    p {
      margin-bottom: 45px;
    }
  }
`
const Main = styled.div`
  background-color: #f7f7f7;
`
const AnswerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.whyMagenta};
  margin-bottom: 20px;
  padding: 60px 0 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin-bottom: 50px;
  }
`

const ComingSoon = styled.div`
  max-width: 1090px;
  padding: 0 15px;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 0 50px;
  }
  margin-bottom: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  h4 {
    font-size: 40px;
    font-weight: 800;
    color: white;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 0 25px;
  }
`
const AnswerCard = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 20px 0 30px;
  width: 95%;
  color: #fff;
  span {
    font-weight: 600;
  }
  .inner-wrap {
    background-color: #18001b33;
    padding: 25px 25px 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    min-height: 0;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding: 20px 15px 25px;
    }
  }
  .highlight {
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    top: -22px;
    left: -10px;
    padding: 10px 19px 8px;
    background-color: ${({ theme }) => theme.colors.whyBlue};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    h4 {
      width: 110px;
      font-size: 20px;
      text-align: center;
      margin: 0;
      letter-spacing: 1.2px;
      line-height: 1em;
    }
  }
  .question {
    position: absolute;
    top: -110px;
    right: -15px;
    width: 250px;
  }
  h3 {
    font-size: 27px;
    font-weight: 800;
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    .question {
      top: -305px;
      right: -100px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    .inner-wrap {
      padding: 30px 60px 40px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 80%;
    padding: 0 0 30px;
    h3 {
      font-size: 26px;
    }
    .highlight {
      left: -60px;
      top: -40px;
      transform: rotate(-22deg);
      h4 {
        font-size: 24px;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    .question {
      position: absolute;
      top: -310px;
      right: -190px;
      width: 370px;
    }
    .highlight {
      left: -80px;
      h4 {
        width: 150px;
        font-size: 31px;
      }
    }
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
`
const CategoryLinks = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  padding: 0;
  width: 90%;
  text-align: center;
  &.conversations {
    padding: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 80%;
    text-align: left;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 100%;
    padding: 0 60px;
  }
  .links {
    margin: 25px auto 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      justify-content: center;
    }
    button {
      &.conversation-button {
        padding: 10px 15px;
        font-size: 18px;
        background-color: ${({ theme }) => theme.colors.whyYellow};
        color: ${({ theme }) => theme.colors.whyBlue};
      }
      margin-right: 20px;
      margin-bottom: 10px;
      background-color: ${({ theme }) => theme.colors.whyOrange};
      border-radius: 8px;
      border: none;
      color: white;
      padding: 9px 24px;
      font-family: ${({ theme }) => theme.font.heading};
      font-weight: 700;
      font-size: 17px;
      cursor: pointer;
      transition: all 0.3s ease;
      :hover {
        background-color: white;
        color: ${({ theme }) => theme.colors.whyOrange};
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      button {
        font-size: 20px;
      }
    }
    &.choose {
      span {
        font-size: 20px;
      }
      @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
        text-align: center;
      }
    }
  }
`
const DontMissSection = styled.div`
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(179deg, #969a50, #787c34);
  overflow: hidden;
  h1 {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 45px;
    padding: 0;
    margin: 10px auto 55px;
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 35px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 30px;
      margin: 10px auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-top: 25px;
  }
`
const Ribbon = styled.div`
  text-align: center;
  width: 350px;
  padding: 18px;
  transform: rotate(-45deg);
  position: relative;
  top: 70px;
  left: -80px;
  font-weight: 700;
  height: 65px;
  font-size: 30px;
  background-color: ${({ theme }) => theme.colors.whyYellow};
  color: ${({ theme }) => theme.colors.whyMagenta};
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    transform: none;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin: 0 0 25px;
  }
`
const CardWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 0 30px 25px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 0 25px 25px;
    margin: 25px auto 0;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin: 0px 0 80px;
  }
`
const IndexPage = ({
  data: { prismicHome, prismicHomeSlider, allPrismicCategory }
}) => {
  const home = prismicHome.data
  const homeSlider = prismicHomeSlider.data.home_slider
  const articleOne = home.section_one_article1
  const articleTwo = home.section_one_article2
  const articleThree = home.section_one_article3
  const articleFour = home.section_three_article1
  const articleFive = home.section_three_article2
  const articleSix = home.section_three_article3
  const cardContent = home.carousel_cards
  const cardLinksContent = [articleFour, articleFive, articleSix]
  const conversationCategories = allPrismicCategory.nodes.filter(
    (cat) => cat.data.category_group === 'Conversations'
  )
  const dontMiss = home.dont_miss_section
  const conversations = home.conversations_section
  const categories = allPrismicCategory.nodes.filter(
    (cat) => cat.data.category_group === 'Answers'
  )

  return (
    <Layout>
      <SEO title="Whyzz tools for worldwise family conversations" />
      <Background>
        <Container width="1075px">
          <TextWrap>
            <Heading>
              <h1>{home.landing_intro}</h1>
              <h2>{home.intro_subtitle}</h2>
              <h3>
                <a href="/about">Learn More</a> or start talking away...
              </h3>
            </Heading>
          </TextWrap>
        </Container>
      </Background>
      <Main>
        <SliderContainer>
          <Container width="1125px">
            <HomeSlider content={homeSlider} />
          </Container>
        </SliderContainer>
        <Container width="1200px">
          <Blurb className="blue">
            <h2>Conversations</h2>
            <RichText
              render={home.section_two_text.raw}
              htmlSerializer={htmlSerializer}
            />
          </Blurb>
          <CategoryLinks className="conversations">
            <div className="links">
              {conversationCategories.map(
                (cat, index) =>
                  index < 7 &&
                  index > 0 && (
                    <Link key={cat.uid} to={`/${cat.uid}`}>
                      <button className="conversation-button">
                        {cat.data.name}
                      </button>
                    </Link>
                  )
              )}
            </div>
          </CategoryLinks>
          <CardSection cardContent={conversations} />
        </Container>
        <AnswerContainer>
          <ComingSoon>
            <Blurb className="blue answer">
              <h2>Answer</h2>
              <p>
                Find <span>kid ready answers</span> to your family's why
                questions...
              </p>
            </Blurb>
            <AnswerCard>
              <div className="inner-wrap">
                <span>Ask a question - {home.answer_subheading}</span>
                <Question className="question" />
                <AnswerBar />
              </div>
              <CategoryLinks>
                <span className="choose">
                  Choose a category and get an answer
                </span>
                <div className="links">
                  {categories.map((cat) => (
                    <Link key={cat.uid} to={`/${cat.uid}`}>
                      <button>{cat.data.name}</button>
                    </Link>
                  ))}
                </div>
              </CategoryLinks>
            </AnswerCard>
          </ComingSoon>
        </AnswerContainer>
        <Container width="1075px">
          <Blurb className="blue quotes">
            <h2>Advice</h2>
            <RichText
              render={home.section_one_text.raw}
              htmlSerializer={htmlSerializer}
            />
          </Blurb>
          <ArticlePreview
            articleOne={articleOne}
            articleTwo={articleTwo}
            articleThree={articleThree}
          />
        </Container>
        <DontMissSection>
          <Ribbon>Don't miss</Ribbon>
          <Wrapper>
            <h1>Need more Whyzz tools?</h1>
            <CardWrapper>
              {dontMiss.map(
                (card, index) =>
                  index < 3 && (
                    <DontMissCard
                      key={index}
                      title={card.title.text}
                      link={card.button_link?.url}
                      image={card.image}
                      subheading={card.subtitle.text}
                      button={card.button_text.text}
                      index={index}
                      type={card.button_link.link_type}
                    />
                  )
              )}
            </CardWrapper>
          </Wrapper>
        </DontMissSection>
      </Main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allPrismicCategory {
      nodes {
        uid
        data {
          name
          category_group
        }
      }
    }
    prismicHome {
      data {
        answer_subheading
        landing_intro
        intro_subtitle
        conversations_section {
          article_link {
            url
            link_type
          }
          image {
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
        dont_miss_section {
          button_text {
            text
          }
          image {
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          subtitle {
            text
          }
          title {
            text
          }
          button_link {
            url
            link_type
          }
        }
        section_one_text {
          raw
        }
        section_one_title
        section_two_text {
          raw
        }
        section_two_title
        section_three_text {
          raw
        }
        section_three_title
        section_one_article1 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                excerpt {
                  raw
                }
                author
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        data {
                          name
                          color
                        }
                      }
                    }
                  }
                }
                subcategories {
                  subcategory {
                    document {
                      ... on PrismicSubcategory {
                        uid
                        data {
                          name
                          parent_category {
                            document {
                              ... on PrismicCategory {
                                id
                                data {
                                  color
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_one_article2 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                excerpt {
                  raw
                }
                author
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        data {
                          name
                          color
                        }
                      }
                    }
                  }
                }
                subcategories {
                  subcategory {
                    document {
                      ... on PrismicSubcategory {
                        uid
                        data {
                          name
                          parent_category {
                            document {
                              ... on PrismicCategory {
                                id
                                data {
                                  color
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_one_article3 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                excerpt {
                  raw
                }
                author
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        data {
                          name
                          color
                        }
                      }
                    }
                  }
                }
                subcategories {
                  subcategory {
                    document {
                      ... on PrismicSubcategory {
                        uid
                        data {
                          name
                          parent_category {
                            document {
                              ... on PrismicCategory {
                                id
                                data {
                                  color
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_three_article1 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        id
                        data {
                          color
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_three_article2 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        id
                        data {
                          color
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_three_article3 {
          document {
            ... on PrismicArticle {
              uid
              data {
                title
                featured_image {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                categories {
                  category {
                    document {
                      ... on PrismicCategory {
                        id
                        data {
                          color
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicHomeSlider {
      data {
        home_slider {
          post_link {
            link_type
            url
            target
          }
          post_image {
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`
/*
 allInstaNode(
      sort: { fields: timestamp, order: DESC }
      limit: 5
    ) {
      nodes {
        caption
        id
        timestamp
        likes
        localFile {
          url
          childImageSharp {
            fluid(quality: 100, maxWidth: 250, maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    */
