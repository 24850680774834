import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FaAmazon, FaInstagram, FaDownload, FaReadme } from 'react-icons/fa'

const Button = styled.div`
  color: white;
  width: fit-content;
  padding: 10px 24px;
  border: none;
  font-weight: 700;
  border-radius: 7px;
  font-family: 'Nunito';
  line-height: 15px;
  display: flex;
  align-items: center;
  align-content: center;
  grid-gap: 5px;
  margin: 25px 0 0;
  height: 50px;
  font-size: 20px;
  padding: 20px 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 18px;
  }
`

const StyledCard = styled.div`
  position: relative;
  z-index: 100;
  margin-bottom: 20px;
  border-radius: 22px;
  overflow: hidden;
  height: 460px;
  width: 25%;
  @media (max-width: 1100px) {
    width: 40%;
    height: 470px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;
    height: auto;
  }
  &:nth-of-type(1) {
    border: 5px solid ${({ theme }) => theme.colors.whyOrange};
    ${Button} {
      background: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &:nth-of-type(2) {
    border: 5px solid ${({ theme }) => theme.colors.whyMagenta};
    ${Button} {
      background: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &:nth-of-type(3) {
    border: 5px solid ${({ theme }) => theme.colors.whyYellow};
    ${Button} {
      background: ${({ theme }) => theme.colors.whyYellow};
    }
  }
`

const TopLayer = styled.div`
  height: 100%;
  z-index: 100;
  background-color: white;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    border-radius: 15px;
    height: auto;
    aspect-ratio: 16/8;
  }
  img {
    width: auto;
    margin: 0 auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 20px;
  }
  &.orange {
    h3 {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    h3 {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.yellow {
    h3 {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
`
const Title = styled.div`
  padding: 20px 0 0;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 35px 0 0;
  }
  p {
    font-size: 16px;
    line-height: 1.3em;
    margin-bottom: 0;
    color: #424141;
  }
  h3 {
    color: ${({ theme }) => theme.colors.whyBlue};
    font-weight: 800;
    font-size: 33px;
    line-height: 1.1em;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 24px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      font-size: 29px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 27px;
    }
  }
`
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: auto;
  }
`

const DontMissCard = ({
  color,
  title,
  link,
  subheading,
  image,
  className,
  button,
  index,
  type
}) => {
  return (
    <StyledCard className={className}>
      <TopLayer className={color}>
        <IconWrap>
          <Img fluid={image?.fluid} alt={image?.alt} />
        </IconWrap>
        <Title>
          <h3>{title}</h3>
          <p>{subheading}</p>
        </Title>
        {index === 0 && (
          <a href={link} target="_blank">
            <Button>
              <FaAmazon />
              {button}
            </Button>
          </a>
        )}
        {index === 1 && (
          <a href={link} target="_blank">
            <Button>
              <FaInstagram />
              {button}
            </Button>
          </a>
        )}
        {index === 2 && (
          <Link to={link}>
            <Button>
              <FaReadme />
              {button}
            </Button>
          </Link>
        )}
      </TopLayer>
    </StyledCard>
  )
}

export default DontMissCard
