import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Carousel from '../secondary/Carousel'
import ArrowRight from '../../images/arrow-right-solid.svg'
import ArrowLeft from '../../images/arrow-left-solid.svg'

const StyledCarousel = styled.div`
  position: relative;
  li {
    display: flex;
    justify-content: center;
  }
  h2 {
    width: 100%;
    text-align: center;
    position: absolute;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: -0.8px;
    color: ${({ theme }) => theme.colors.whyBlue};
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      font-size: 41px;
    }
  }
  .react-multi-carousel-list {
    padding-top: 70px;
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      padding-top: 90px;
    }
  }
  .react-multi-carousel-track {
    margin-bottom: 45px;
  }
  .react-multiple-carousel__arrow {
    background-color: ${({ theme }) => theme.colors.whyMagenta};
    padding: 5px 0 0 2px;
    border-radius: 5px;
    width: 40px;
    height: 35px;
    &:before {
      border: none;
      width: 20px;
      height: 20px;
    }
  }

  .react-multiple-carousel__arrow--left {
    &:before {
      transform: none;
      background: no-repeat center url(${ArrowLeft});
    }
    top: 8px;
    left: initial;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      right: 67px;
    }
  }
  .react-multiple-carousel__arrow--right {
    &:before {
      background: no-repeat center url(${ArrowRight});
      transform: none;
    }
    top: 8px;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      right: 9px;
    }
  }
`
const Post = styled.div`
  display: inline-block;
  width: 92%;
  a {
    width: 100%;
  }
  img {
    width: 100%;
    border-radius: 20px;
  }
`
const HomeSlider = ({ content }) => {
  //const contentSlice = content.slice(0, 4)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 649, min: 0 },
      items: 1
    }
  }

  return (
    <StyledCarousel>
      <h2>NEW: This Week</h2>
      <Carousel responsive={responsive} showDots>
        {content.map((post, index) => {
          if (post.post_link.link_type === 'document') {
            return (
              <Post key={`post-${index}`}>
                <Link to={`/${post.post_link.url}`}>
                  <Img fluid={post.post_image.fluid} />
                </Link>
              </Post>
            )
          } else {
            return (
              <Post key={`post-${index}`}>
                <a
                  href={post.post_link.url}
                  target={post.post_link.target}
                  rel="noreferrer noopener"
                >
                  <Img fluid={post.post_image.fluid} />
                </a>
              </Post>
            )
          }
        })}
      </Carousel>
    </StyledCarousel>
  )
}

export default HomeSlider
