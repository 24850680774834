import React from 'react'
import CardWithDetail from '../elements/CardWithDetail'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 45px;
  justify-content: space-between;
  flex-direction: column;
  .large {
    position: relative;
  }
  .preview {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: row;
    margin-bottom: 75px;
    margin-top: 10px;
    .preview {
      width: 49%;
    }
  }
`
const New = styled.span`
  background-color: ${({ theme }) => theme.colors.whyOrange};
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 19px;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: block;
  }
`

const ArticlePreview = ({ articleOne, articleTwo, articleThree }) => {
  const one = articleOne.document.data
  const two = articleTwo.document.data
  const three = articleThree.document.data
  return (
    <Wrapper>
      <div className="preview large">
        <CardWithDetail
          title={one.title}
          author={one.author}
          excerpt={one.excerpt.raw}
          image={one.featured_image}
          link={articleOne.document.uid}
          subcategories={one.subcategories}
          category={one.categories[0]?.category.document.data}
          size="large"
        />
        <New>New</New>
      </div>
      <div className="preview small">
        <CardWithDetail
          title={two.title}
          author={two.author}
          excerpt={two.excerpt.raw}
          image={two.featured_image}
          link={articleTwo.document.uid}
          subcategories={two.subcategories}
          category={two.categories[0]?.category.document.data}
          size="small"
        />
        <CardWithDetail
          title={three.title}
          author={three.author}
          excerpt={three.excerpt.raw}
          image={three.featured_image}
          link={articleThree.document.uid}
          subcategories={three.subcategories}
          category={three.categories[0]?.category.document.data}
          size="small"
        />
      </div>
    </Wrapper>
  )
}

export default ArticlePreview
