import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledCard = styled.div`
  position: relative;
  z-index: 100;
  margin-bottom: 20px;
  width: 48%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const TopLayer = styled.div`
  border-radius: 22px;
  height: 100%;
  z-index: 100;
  background-color: white;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  .gatsby-image-wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    border-radius: 15px;
    height: 250px;
  }
  img {
    width: auto;
    margin: 0 auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 20px;
  }
  &.orange {
    h3 {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    h3 {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.yellow {
    h3 {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
`
const Title = styled.div`
  padding: 20px 0 0;
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 35px 0 0;
  }
  p {
    font-size: 16px;
    line-height: 1.3em;
    margin-bottom: 0;
    color: #424141;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 14px;
    }
  }
  h3 {
    color: ${({ theme }) => theme.colors.whyBlue};
    font-weight: 800;
    font-size: 33px;
    line-height: 1.1em;
    margin-bottom: 10px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 20px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      font-size: 29px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 27px;
    }
  }
`
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    height: auto;
  }
`
const Button = styled(Link)`
  background: ${({ theme }) => theme.colors.whyBlue};
  color: white;
  height: 35px;
  width: fit-content;
  padding: 10px 24px;
  border: none;
  font-weight: 700;
  border-radius: 7px;
  font-family: 'Nunito';
  font-size: 15px;
  line-height: 15px;
  margin: 25px 0 0;
  cursor: pointer;
  transition: 0.3s all;
`

const Card = ({ color, title, link, subheading, image, className }) => {
  if (link.link_type === 'document') {
    return (
      <StyledCard className={className}>
        <TopLayer className={color}>
          <IconWrap>
            <Img fluid={image?.fluid} alt={image?.alt} />
          </IconWrap>
          <Title>
            <h3>{title}</h3>
            <p>{subheading}</p>
          </Title>
          <Button to={link}>Start the Conversation</Button>
        </TopLayer>
      </StyledCard>
    )
  } else {
    return (
      <StyledCard className={className}>
        <TopLayer className={color}>
          <IconWrap>
            <Img fluid={image?.fluid} alt={image?.alt} />
          </IconWrap>
          <Title>
            <h3>{title}</h3>
            <p>{subheading}</p>
          </Title>
          <Button to={link}>Start the Conversation</Button>
        </TopLayer>
      </StyledCard>
    )
  }
}

export default Card
